var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event"},[_c('div',{staticClass:"time desktop"},[_c('span',[_vm._v(_vm._s(_vm.formatHour(_vm.event.created_at)))])]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"type"},[_c('div',{staticClass:"bar"}),_c('div',{staticClass:"time mobile"},[_c('span',[_vm._v(_vm._s(_vm.formatHour(_vm.event.created_at)))])]),_c('div',{staticClass:"d-flex flex-column",class:{ flex: _vm.isMobile }},[_c('p',[_vm._v(_vm._s(_vm.getEventType(_vm.event.type)))]),_c('span',{staticClass:"unity"},[_vm._v(_vm._s(_vm.event.unit)+" - "+_vm._s(_vm.event.source))])]),_c('div',{staticClass:"store",attrs:{"title":`${_vm.event.unit} - ${_vm.event.source}`}},[_c('i',{staticClass:"fal fa-store"})]),(_vm.event.type !== _vm.TYPES.CONVERSION && _vm.event.customer && _vm.isMobile)?_c('base-button',{attrs:{"design":"three mini icon"},on:{"click":function($event){return _vm.$emit('showProfile', _vm.event.customer && _vm.event.customer.id)}}},[_c('i',{staticClass:"far fa-user"})]):_vm._e()],1),_c('div',{staticClass:"infos"},[(!_vm.event.customer)?_c('p',{staticClass:"not-found"},[_vm._v("\n        "+_vm._s(_vm.event.type === _vm.TYPES.NEW_ACCOUNT
            ? 'Nome não registrado'
            : 'Cliente não identificado')+"*\n      ")]):_vm._e(),(
          _vm.event.customer &&
          _vm.event.customer.name &&
          _vm.TYPES.CHANGE_NAME !== _vm.event.type &&
          _vm.TYPES.NEW_ACCOUNT !== _vm.event.type
        )?_c('p',{staticClass:"name"},[_vm._v("\n        "+_vm._s(_vm.event.customer.name)+"\n      ")]):_vm._e(),(_vm.event.customer)?_c('p',{staticClass:"phone"},[_c('i',{staticClass:"fal fa-mobile"}),_vm._v("\n        "+_vm._s(_vm.event.customer ? _vm.formatNumber(_vm.event.customer.phone) : '')+"\n      ")]):_vm._e(),(_vm.event.type != _vm.TYPES.NEW_ACCOUNT)?_c('p',{class:{ [_vm.event.type]: true }},[(_vm.hasIcon(_vm.event.type))?_c('i',{class:_vm.getIcon(_vm.event.type)}):_vm._e(),_vm._v("\n        "+_vm._s(_vm.formatValue(_vm.event.type, _vm.event.value))+"\n      ")]):_vm._e(),(_vm.event.type == _vm.TYPES.NEW_ACCOUNT)?_c('p',{class:{ 'not-found': !_vm.formatValue(_vm.event.type, _vm.event.value) }},[_vm._v("\n        "+_vm._s(_vm.formatValue(_vm.event.type, _vm.event.value) || 'Nome não registrado*')+"\n      ")]):_vm._e()]),_c('div',{staticClass:"buttons"},[(_vm.event.customer && !_vm.isMobile)?_c('base-button',{attrs:{"design":"three mini ml_15"},on:{"click":function($event){return _vm.$emit('showProfile', _vm.event.customer && _vm.event.customer.id)}}},[_vm._v("\n        Ver perfil\n      ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }