<template>
  <base-card>
    <div class="infos">
      <div class="data">
        <div class="item">
          <p>Quantidade de conversões</p>
          <span>{{ details.count_orders || 0 }}</span>
        </div>
        <div class="item">
          <p>Ticket Médio</p>
          <span>
            {{ calcTicket(details.total_orders, details.count_orders) }}</span
          >
        </div>
        <div class="item">
          <p>Total gasto</p>
          <span>
            {{
              (+details.total_orders || 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            }}</span
          >
        </div>
        <div class="item">
          <p>Cashback acumulado</p>
          <span> 
            {{ 
              (+details.cashback_total || 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            }}</span>
        </div>
        <div class="item">
          <p>Última conversão</p>
          <span>{{ formatDate(details.last_conversion_at) }}</span>
        </div>
      </div>

      <div class="completeness">
        <div class="progress">
          <div class="circle">
            <svg>
              <circle cx="25" cy="25" r="25"></circle>
              <circle
                cx="25"
                cy="25"
                r="25"
                :style="`stroke-dashoffset: calc(165 - (165 * ${calculatePercenteFinish(
                  details
                )}) / 100)`"
              ></circle>
            </svg>
            <div class="number">
              <span>{{ calculatePercenteFinish(details) }}%</span>
            </div>
          </div>

          <p>Dados concluídos</p>
        </div>

        <div class="done">
          <base-checkbox
            v-model="details.phone"
            :checked="checkInputDates(details.phone && 'phone')"
            class="mb_15"
            disabled
            :notclickable="!details.phone"
          >
            Telefone
          </base-checkbox>
          <base-checkbox
            v-model="details.name"
            :checked="checkInputDates(details.name && 'name')"
            class="mb_15"
            disabled
            :notclickable="!details.name"
          >
            Nome
          </base-checkbox>
          <base-checkbox
            v-model="details.email"
            :checked="checkInputDates(details.email && 'email')"
            class="mb_15"
            disabled
            :notclickable="!details.email"
          >
            Email
          </base-checkbox>

          <base-checkbox
            v-model="details.birthday"
            :checked="checkInputDates(details.birthdate && 'birthdate')"
            class="mb_15"
            disabled
            :notclickable="!details.birthdate"
          >
            Aniversário
          </base-checkbox>
          <base-checkbox
            v-model="details.addresses"
            :checked="checkInputAddresses(details.addresses)"
            disabled
            :notclickable="!checkInputAddresses(details.addresses)"
          >
            Endereço
          </base-checkbox>
        </div>
      </div>
    </div>

    <base-card>
      <base-label text="Conversões no ano" class="text-center" />

      <chart-box
        class="chart"
        type="line"
        :chartData="chartData"
        :chartOptions="options"
        :key="'customer-chart-' + randID"
      />

      <div class="msg-charts">
        <base-message>
          Para ver os gráficos disponíveis, vire seu celular em modo paisagem.
        </base-message>
      </div>
    </base-card>
  </base-card>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';

import BaseCheckbox from '@/components/Checkbox';
import BaseCard from '@/components/Card';
import BaseMessage from '@/components/Message';
import BaseLabel from '@/components/Label';
import ChartBox from '@/containers/ChartBox';

export default {
  components: {
    BaseCheckbox,
    BaseCard,
    BaseMessage,
    BaseLabel,
    ChartBox,
  },
  data() {
    return {
      randID: '',
      controllers: ['phone', 'name', 'email', 'birthdate', 'addresses'],
      details: {},
      chartData: {
        labels: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
        datasets: [
          {
            label: 'Conversões',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            borderWidth: 1,
            backgroundColor: '#6161ff20',
            borderColor: '#6161ff',
            pointBackgroundColor: '#6161ff',
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        cutoutPercentage: 62.5,
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
          fullWidth: false,
        },
      },
      drawer: {
        open: false,
        close: false,
      },
      completeness: {
        phone: true,
        name: true,
        email: false,
        birthday: true,
        address: false,
      },
    };
  },
  props: {
    clientValue: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.randID = Math.random();
    this.details = this.clientValue;
    this.mountGraphic(this.clientValue.orders);
  },
  watch: {
    clientValue(newVal) {
      this.randID = Math.random();
      this.details = newVal;
      this.mountGraphic(newVal.orders);
    },
  },

  methods: {
    mountGraphic(parms) {
      if (parms !== undefined && (parms || []).length > 0) {
        parms.map((item) => {
          const month = dayjs(item.created_at).month();
          this.chartData.datasets[0].data[month] += 1;
        });
      }
    },
    checkInputDates(data) {
      if (this.controllers.includes(data)) {
        return true;
      }
      return false;
    },
    checkInputAddresses(addresses) {
      if (!addresses) return false;
      if (addresses.length === 0) return false;

      return (
        addresses.find((address) => {
          if (address.geolocation) delete address.geolocation;
          return Object.values(address).find((field) => field !== null);
        }) !== undefined
      );
    },
    isArrayEmpty(value) {
      return Array.isArray(value) && value.length == 0
    },
    calculatePercenteFinish(data) {
      const values = [];
      
      this.controllers.map((item) => {
        if (data[item] !== undefined && !this.isArrayEmpty(data[item]) && data[item] !== null) {
          values.push(data[item]);
        }
      });
      const percent = ((values || []).length * 100) / 5;
      return Math.trunc(percent);
    },
    formatDate(date) {
      if (!date) {
        return '-';
      }
      return date
        .substring(0, 10)
        .split('-')
        .reverse()
        .join()
        .replaceAll(',', '/');
    },
    calcTicket(total_orders, count_orders) {
      return (
        (total_orders / (count_orders || 1)).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }) || 0
      );
    },

    openDrawer() {
      this.drawer.open = !this.drawer.open;
      this.drawer.close = false;
    },
    closeDrawer() {
      this.drawer.open = false;
      this.drawer.close = !this.drawer.close;
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.infos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.infos :is(.data, .completeness) {
  background: #2e3438;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.infos .completeness {
  padding: 20px 0;
}

.infos .data .item:not(:first-child) {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #6f767e33;
}

.infos .data .item p {
  color: #aaaaab;
  opacity: 0.6;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
}

.infos .data .item span {
  color: #aaaaab;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.9;
}

.progress {
  padding-top: 3px;
  padding-bottom: 20px;
  border-bottom: 1px solid #6f767e33;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress .circle {
  position: relative;
  width: 50px;
  height: 50px;
  transform: translateX(-5px);
}

.progress .circle svg {
  position: relative;
  width: 60px;
  height: 60px;
  transform: rotate(270deg);
}

.progress .circle svg circle {
  fill: none;
  stroke-width: 3;
  stroke: #000;
  transform: translate(5px, 5px);
  stroke-dasharray: 165;
  stroke-dashoffset: 165;
}
.progress .circle svg circle:nth-child(1) {
  stroke-dashoffset: 0;
  stroke: #525259;
}
.progress .circle svg circle:nth-child(2) {
  stroke: #fff1ad;
}
.progress .circle .number {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  color: #ebebeb;
  transform: translate(5px, 5px);
}

.progress p {
  font-size: 12px;
  line-height: 14px;
  color: #aaaaab;
  margin-top: 20px;
}

.infos .completeness .done {
  padding: 20px 20px 0;
}

.msg-charts {
  display: none;
}

.chart {
  background: #2e3438;
  border-radius: 10px;
  margin-top: 20px;
}

@media screen and (max-width: 991px) {
  @media screen and (orientation: portrait) {
    .chart {
      opacity: 0;
      position: absolute;
      top: 0;
      width: 0;
      overflow: hidden;
    }
    .msg-charts {
      display: block;
    }
  }
}
</style>
