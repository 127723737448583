<template>
  <div>
    <base-drawer
      title="Filtrar intervalo"
      :open="drawer.open"
      :close="drawer.close"
    >
      <base-card>
        <base-label text="Selecione um intervalo" />
        <base-select
          title="Intervalo"
          :items="options"
          v-model="period"
          class="mt_20 mb_20"
        >
        </base-select>

        <base-message v-if="period == 4 && maxCustomRange > 0" class="mt_20">
          Só é possível filtrar intervalos de no máximo {{ rangeDisplay }}.
        </base-message>

        <div class="row half-cols">
          <div class="col-lg-4 col-xs-4 col-md-4" v-if="period == 1">
            <base-select title="Dia" class="mb_5" v-model="day">
              <option :value="l" v-for="l in parseInt(lastDayOfMonth)" :key="l">
                {{ String(l).padStart(2, '0') }}
              </option>
            </base-select>
          </div>

          <div class="col-lg-8 col-md-8 col-xs-8" v-if="period == 1">
            <base-select title="Mês/Ano" class="mb_5" v-model="monthYear">
              <option
                :value="ma.value"
                v-for="(ma, i) in MonthYearMatrix"
                :key="i"
              >
                {{ ma.label }}
              </option>
            </base-select>
          </div>

          <div class="col-lg-6 col-xs-6 col-md-6" v-if="period == 2">
            <base-select title="Mês" class="mb_5" v-model="month">
              <option v-for="(m, i) in months" :key="i" :value="m.value">
                {{ m.label }}
              </option>
            </base-select>
          </div>

          <div
            :class="
              period == 2
                ? 'col-lg-6 col-md-6 col-xs-6'
                : 'col-lg-12 col-md-12 col-xs-12 px_15'
            "
            v-if="period == 2 || period == 3"
          >
            <base-select title="Ano" class="mb_0" v-model="year">
              <option
                v-for="y in 5"
                :key="y"
                :value="`${DateTime.format('Y', new Date()) - (y - 1)}`"
              >
                {{ DateTime.format('Y', new Date()) - (y - 1) }}
              </option>
            </base-select>
          </div>
        </div>
      </base-card>

      <base-card v-if="period == 4">
        <base-label text="Inicio do intervalo em:" />
        <div class="row mt_20">
          <div class="col-lg-8 col-md-8 col-xs-8">
            <base-select
              title="Mês"
              class="mb_5"
              v-model="month"
              @change="setFinalPeriod"
            >
              <option v-for="(m, i) in months" :key="i" :value="m.value">
                {{ m.label }}
              </option>
            </base-select>
          </div>
          <div class="col-lg-4 col-md-4 col-xs-4 pl_0">
            <base-select
              title="Ano"
              class="mb_0"
              v-model="year"
              @change="setFinalPeriod"
            >
              <option
                v-for="y in 5"
                :key="y"
                :value="`${DateTime.format('Y', new Date()) - (y - 1)}`"
              >
                {{ DateTime.format('Y', new Date()) - (y - 1) }}
              </option>
            </base-select>
          </div>
        </div>

        <div class="row half-cols mt_20">
          <div class="col-lg-6 col-xs-6 col-md-6">
            <base-select
              title="Dia"
              class="mb_5"
              v-model="day"
              @change="setFinalPeriod"
            >
              <option :value="l" v-for="l in parseInt(lastDayOfMonth)" :key="l">
                {{ String(l).padStart(2, '0') }}
              </option>
            </base-select>
          </div>
          <div class="col-lg-6 col-xs-6 col-md-6">
            <base-select
              title="Hora"
              class="mb_5"
              v-model="hour"
              @change="setFinalPeriod"
            >
              <option :value="0">00:00</option>
              <option :value="1">01:00</option>
              <option :value="2">02:00</option>
              <option :value="3">03:00</option>
              <option :value="4">04:00</option>
              <option :value="5">05:00</option>
              <option :value="6">06:00</option>
              <option :value="7">07:00</option>
              <option :value="8">08:00</option>
              <option :value="9">09:00</option>
              <option :value="10">10:00</option>
              <option :value="11">11:00</option>
              <option :value="12">12:00</option>
              <option :value="13">13:00</option>
              <option :value="14">14:00</option>
              <option :value="15">15:00</option>
              <option :value="16">16:00</option>
              <option :value="17">17:00</option>
              <option :value="18">18:00</option>
              <option :value="19">19:00</option>
              <option :value="20">20:00</option>
              <option :value="21">21:00</option>
              <option :value="22">22:00</option>
              <option :value="23">23:00</option>
            </base-select>
          </div>
        </div>
      </base-card>

      <base-card v-if="period == 4">
        <base-label text="Final do intervalo em:" />
        <div class="row mt_20">
          <div class="col-lg-8 col-md-8 col-xs-8">
            <base-select
              title="Mês"
              class="mb_5"
              v-model="final.month"
              @change="setFinalPeriod(false)"
              :disabled="maxCustomRange > 0"
            >
              <option
                v-for="(m, i) in this.final.months"
                :key="i"
                :value="m.value"
              >
                {{ m.label }}
              </option>
            </base-select>
          </div>
          <div class="col-lg-4 col-md-4 col-xs-4 pl_0">
            <base-select
              title="Ano"
              v-model="final.year"
              class="mb_0"
              @change="setFinalPeriod(false)"
              :disabled="maxCustomRange > 0"
            >
              <!-- <option v-for="y in 5" :key="y" :value="`${DateTime.format('Y', new Date())-(y-1)}`">{{ DateTime.format('Y', new Date())-(y-1) }}</option> -->
              <option v-for="y in this.final.years" :value="y" :key="y">
                {{ y }}
              </option>
            </base-select>
          </div>
        </div>

        <div class="row half-cols mt_20">
          <div class="col-lg-6 col-xs-6 col-md-6">
            <base-select
              title="Dia"
              v-model="final.day"
              class="mb_5"
              @change="setFinalPeriod(false)"
            >
              <option :value="fd" v-for="fd in final.days" :key="fd">
                {{ String(fd).padStart(2, '0') }}
              </option>
            </base-select>
          </div>
          <div class="col-lg-6 col-xs-6 col-md-6">
            <base-select title="Hora" v-model="final.hour" class="mb_5">
              <option :value="h.value" v-for="h in final.hours" :key="h.value">
                {{ h.display }}
              </option>
            </base-select>
          </div>
        </div>
      </base-card>

      <template v-slot:footer>
        <base-button @click="filter" :text="'Filtrar'" class="w-100" />
      </template>
    </base-drawer>
  </div>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseLabel from '@/components/Label';
import BaseSelect from '@/components/Select';
import BaseMessage from '@/components/Message';
import BaseButton from '@/components/Button';
import BaseDrawer from '@/components/Drawer';

import Utils from '@/utils';
import { DateTime } from 'easyjs/lib/Tools';
export default {
  props: {
    maxCustomRange: {
      default: 24,
    },
    open: {},
    close: {},
    defaultOption: {
      default: 2,
    },
    dayFilter: {
      default: true,
    },
    monthFilter: {
      default: true,
    },
    yearFilter: {
      default: true,
    },
    customFilter: {
      default: true,
    },
    allFilter: {
      default: false,
    },
  },
  data() {
    return {
      DateTime,
      drawer: {
        open: false,
        close: false,
      },
      period: this.defaultOption,
      months: [
        { value: '01', label: 'Janeiro' },
        { value: '02', label: 'Fevereiro' },
        { value: '03', label: 'Março' },
        { value: '04', label: 'Abril' },
        { value: '05', label: 'Maio' },
        { value: '06', label: 'Junho' },
        { value: '07', label: 'Julho' },
        { value: '08', label: 'Agosto' },
        { value: '09', label: 'Setembro' },
        { value: '10', label: 'Outubro' },
        { value: '11', label: 'Novembro' },
        { value: '12', label: 'Dezembro' },
      ],
      year: DateTime.format('Y', new Date()),
      month: DateTime.format('m', new Date()),
      monthYear: DateTime.format('m-Y', new Date()),
      day: parseInt(DateTime.format('d', new Date())),
      hour: 0,
      final: {
        days: [],
        hours: [],
        day: parseInt(DateTime.format('d', new Date())),
        hour: 0,
        month: DateTime.format('m', new Date()),
        months: [],
        year: DateTime.format('Y', new Date()),
        years: [],
      },
      options: [
        {
          value: 5,
          selected: 'true',
          text: 'Desde o inicio',
          disabled: !this.allFilter,
        },
        { value: 1, text: 'Dia específico', disabled: !this.dayFilter },
        { value: 2, text: 'Mês específico', disabled: !this.monthFilter },
        { value: 3, text: 'Ano específico', disabled: !this.yearFilter },
        {
          value: 4,
          text: 'Intervalo personalizado',
          disabled: !this.customFilter,
        },
      ],
    };
  },
  components: {
    BaseCard,
    BaseLabel,
    BaseSelect,
    BaseMessage,
    BaseButton,
    BaseDrawer,
  },
  methods: {
    filter() {
      let startPeriod = '';
      let endPeriod = '';
      switch (this.period) {
        case '1':
          startPeriod = `${this.monthYear.split('-')[1]}-${
            this.monthYear.split('-')[0]
          }-${String(this.day).padStart(2, '0')} 00:00:00`;
          endPeriod = `${this.monthYear.split('-')[1]}-${
            this.monthYear.split('-')[0]
          }-${String(this.day).padStart(2, '0')} 23:59:59`;
          break;
        case '2':
          startPeriod = `${this.year}-${this.month}-01 00:00:00`;
          endPeriod = `${this.year}-${this.month}-${this.lastDayOfMonth} 23:59:59`;
          break;
        case '3':
          startPeriod = `${this.year}-01-01`;
          endPeriod = `${this.year}-12-31`;
          break;
        case '4':
          startPeriod = `${this.year}-${this.month}-${String(this.day).padStart(
            2,
            '0'
          )} ${String(this.hour).padStart(2, '0')}:00:00`;
          endPeriod = `${this.final.year}-${this.final.month}-${String(
            this.final.day
          ).padStart(2, '0')} ${String(this.final.hour).padStart(
            2,
            '0'
          )}:00:00`;
          break;
        case '5':
          (startPeriod = '1900-01-01 00:00:00'),
            (endPeriod = DateTime.format('Y-m-d H:i:s', new Date()));
      }

      this.$emit('filter', {
        type: this.period,
        periods: [startPeriod, endPeriod],
      });
    },
    setcustomFinalPeriod() {
      if (this.period == 4) {
        // if (this.maxCustomRange == 0) {
        //   return true
        // }

        this.final.days = [];
        this.final.hours = [];
        this.final.days.push(this.day);
        let newDay = false;
        if (this.maxCustomRange > 0) {
          for (
            let i = parseInt(this.hour) + 1;
            i <= parseInt(this.hour) + this.maxCustomRange;
            i++
          ) {
            const hour = i;
            newDay = false;
            if (i >= 24) {
              hour = i - 24;
              newDay = true;
            }
            this.final.hours.push({
              display: `${String(hour).padStart(2, '0')}:00`,
              value: hour,
            });
          }

          if (newDay) {
            this.final.days.push(parseInt(this.day) + 1);
          }
        } 
      }
    },
    setFinalPeriod(init = true) {
      if (this.period == 4 && this.maxCustomRange == 0) {
        // set year
        this.final.years = [];
        for (
          let i = parseInt(this.year);
          i <= DateTime.format('Y', new Date());
          i++
        ) {
          this.final.years.push(i);
        }
        if (init) {
          this.final.year = parseInt(this.year);
        }

        // set month
        this.final.months = [];
        if (parseInt(this.year) === parseInt(this.final.year)) {
          // console.log(this.month)
          this.months.forEach((item, i) => {
            if (parseInt(this.month) - 1 <= i) {
              this.final.months.push(item);
            }
          });
        } else {
          this.final.months = this.months;
        }

        // set days
        this.final.days = [];
        const initdays =
          this.year == this.final.year && this.month == this.final.month
            ? this.day
            : 1;
        const lastDays = parseInt(
          this.DateTime.format(
            'd',
            DateTime.lastDayOfMonth(
              this.final.year,
              parseInt(this.final.month) - 1
            )
          )
        );

        for (let i = parseInt(initdays); i <= lastDays; i++) {
          this.final.days.push(i);
        }

        //set hours
        this.final.hours = [];

        const inithour =
          this.year == this.final.year &&
          this.month == this.final.month &&
          this.day == this.final.day
            ? parseInt(this.hour) + 1
            : 0;

        for (let i = inithour; i < 24; i++) {
          this.final.hours.push({
            display: `${String(i).padStart(2, '0')}:00`,
            value: i,
          });
        }
      } else {
        this.final.days = [];
        this.final.hours = [];
        this.final.days.push(this.day);
        let newDay = false;

        this.final.months = this.months;
        this.final.month = this.month;

        this.final.years = [];
        for (
          let i = parseInt(this.year);
          i <= DateTime.format('Y', new Date());
          i++
        ) {
          this.final.years.push(i);
        }
        this.final.year = this.year;

        for (
          let i = parseInt(this.hour) + 1;
          i <= parseInt(this.hour) + this.maxCustomRange;
          i++
        ) {
          const hour = i;
          newDay = false;
          if (i >= 24) {
            hour = i - 24;
            newDay = true;
          }
          this.final.hours.push({
            display: `${String(hour).padStart(2, '0')}:00`,
            value: hour,
          });
        }

        if (newDay) {
          this.final.days.push(parseInt(this.day) + 1);
        }
      }
    },
  },
  computed: {
    MonthYearMatrix() {
      const matrix = [];
      for (let y = 2015; y <= this.year; y++) {
        for (let m = 0; m <= 11; m++) {
          matrix.push({
            value: `${String(m + 1).padStart(2, '0')}-${y}`,
            label: `${Utils.getMonthNames(m + 1)} - ${y}`,
          });
        }
      }
      return matrix;
    },
    lastDayOfMonth() {
      return this.DateTime.format(
        'd',
        DateTime.lastDayOfMonth(this.year, parseInt(this.month) - 1)
      );
    },
    rangeDisplay() {
      const days = parseInt(this.maxCustomRange / 24);
      const dayDisplay =
        days > 0 ? (days > 1 ? `${days} dias` : `${days} dia`) : '';
      const hours = parseInt(this.maxCustomRange % 24);
      const hourDisplay =
        hours > 0 ? (hours > 1 ? `${hours} horas` : `${hours} hora`) : '';
      return `${dayDisplay} ${hourDisplay}`;
    },
    processedOptions() {
      const filtered = [];
      for (const option of this.options) {
        // if (option.value ==)
      }
      return filtered;
    },
  },
  watch: {
    period() {
      // this.setcustomFinalPeriod()
      this.setFinalPeriod();
    },
    open() {
      this.drawer.open = !this.drawer.open;
    },
    close() {
      this.drawer.close = !this.drawer.close;
    },
  },
};
</script>
