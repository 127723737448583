<template>
  <div v-if="show" class="content-load" :class="{ 'full-loading': !template }">
    <base-loading color="#6161ff" />
  </div>
</template>

<script>
import BaseLoading from '@/components/Loading';

export default {
  name: 'loading',
  props: {
    show: {
      default: false,
    },
    progress: {
      default: 0,
      type: Number,
    },
    template: {
      default: true,
    },
    fullLoading: {
      default: true,
    },
  },
  components: {
    BaseLoading,
  },
};
</script>
<style scoped>
.content-load {
  position: fixed;
  top: 30px;
  left: 60px;
  width: calc(100vw - 60px);
  height: calc(100vh - 30px);
  z-index: 500;
  background-color: var(--background-up);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 20px;
}

.full-loading {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}

@media screen and (max-width: 991px) {
  .content-load {
    top: 0px;
    left: 0;
    height: 100%;
    width: 100vw;
    border-top-left-radius: 0;
    background: var(--background);
  }
}
</style>
