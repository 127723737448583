<template>
  <base-card>
    <div class="customer-details">
      <div class="header">
        <p class="name">{{ data.name }}</p>
        <span class="since">
          Cliente desde {{ formatDate(data.logs.customer[0].created_at) }}
        </span>
      </div>
      <div class="item">
        <div class="icon"><i class="far fa-envelope-open" /></div>

        <p>{{ data.email || 'E-mail não informado!' }}</p>
      </div>
      <div class="item">
        <div class="icon"><i class="far fa-phone" /></div>
        <p>{{ formatNumber(data.phone) || 'Telefone não informado!' }}</p>
      </div>
      <div class="item">
        <div class="icon"><i class="far fa-address-card" /></div>
        <p>{{ formatDocument(data.doc) || 'CPF não informado!' }}</p>
      </div>
      <div class="item">
        <div class="icon"><i class="far fa-birthday-cake" /></div>
        <p>{{ formatDate(data.birthdate) }}</p>
      </div>
      <div
        class="item"
        :class="{ 'align-start': (nonRepeatedAddresses || []).length > 0 }"
      >
        <div class="icon"><i class="far fa-map-marker-alt"></i></div>
        <div class="addresses" v-if="(nonRepeatedAddresses || []).length > 0">
          <p v-for="(address, index) in nonRepeatedAddresses" :key="index">
            {{ formatAddress(address) || 'Endereço não informado!' }}
          </p>
        </div>
        <div class="addresses" v-else>
          <p>Endereço não informado!</p>
        </div>
      </div>
    </div>
  </base-card>
</template>

<script>
import BaseCard from '@/components/Card';

export default {
  components: { BaseCard },
  props: {
    customers: [],
    clientValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {},
      nonRepeatedAddresses: [],
    };
  },
  watch: {
    clientValue(newVal) {
      this.data = newVal;
    },
  },
  created() {
    this.data = this.clientValue;

    const normalizedAddresses = [];

    this.data.addresses = this.data.addresses
      ? this.data.addresses.filter((addr) => {
          if (Object.keys(addr).length === 0) return false;
          if (addr.geolocation) delete addr.geolocation;

          Object.keys(addr).forEach((key) => {
            if (!addr[key]) return delete addr[key];

            addr[key] =
              typeof addr[key] === 'string' ? addr[key].trim() : addr[key];
          });

          return normalizedAddresses.push(addr);
        })
      : null;

    this.nonRepeatedAddresses = [
      ...new Set(normalizedAddresses.map((a) => JSON.stringify(a))),
    ].map((a) => JSON.parse(a));
  },
  methods: {
    formatNumber(number) {
      if (!number) return number;
      const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
      const str = number.replace(/[^0-9]/g, '').slice(2, 13);
      return str.replace(regex, '($1) $2-$3');
    },
    formatAddress(address) {
      if (address.geolocation) delete address.geolocation;

      let formattedAddress = address.street ? `${address.street}, ` : '';
      formattedAddress = formattedAddress.concat(
        address.number ? `${address.number} | ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.complement ? `${address.complement} | ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.neighborhood ? `${address.neighborhood} - ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.city ? `${address.city}/` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.state ? `${address.state} ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.zipcode
          ? `(${address.zipcode.replace(/^(\d{5})(\d)/, '$1-$2')})`
          : ''
      );

      return formattedAddress;
    },
    formatDocument(doc) {
      if (!doc) return null;
      return `${doc.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}`;
    },
    formatDate(date) {
      if (!date) {
        return 'Data não informada!';
      }
      return date
        .substring(0, 10)
        .split('-')
        .reverse()
        .join()
        .replaceAll(',', '/');
    },
  },
};
</script>

<style scoped>
.customer-details {
  padding: 20px;
  border: 2px dashed #6f767e73;
  background: #2e34388c;
}

.customer-details .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #ffffff1a;
  padding-bottom: 20px;
  flex-wrap: wrap;
}

.customer-details .header .name {
  color: #aaaaab;
  opacity: 0.95;
  font-size: 18px;
  line-height: 22px;
}

.customer-details .header .since {
  color: #aaaaaa;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
}

.customer-details .item {
  display: flex;
  margin-top: 15px;
}

.customer-details .item:not(.align-start) {
  align-items: center;
}

.customer-details .item .icon {
  font-size: 15px;
  display: flex;
  color: #6f767e;
  justify-content: center;
  align-items: center;
  background: #6f767e0d;
  min-width: 34px;
  height: 34px;
  border-radius: 10px;
}

.customer-details .item p {
  font-size: 12px;
  line-height: 14px;
  color: #aaaaaa;
  margin-left: 15px;
}

.customer-details .item .addresses p {
  background: #2a2f33;
  border-radius: 10px;
  border: 1px solid #6f767e26;
  padding: 10px 15px;
}

.customer-details .item .addresses p:not(:last-child) {
  margin-bottom: 5px;
}
</style>
