<template>
  <div>
    <div class="loading" v-if="loading">
      <base-loading color="#6161ff" />
    </div>

    <base-card v-if="objDate.length > 0 && !loading">
      <div class="records" v-for="(hist, i) in objDate" :key="i">
        <p class="date">{{ formatDay(hist) }}</p>

        <div class="timeline">
          <div class="dot" />

          <div
            class="record"
            v-for="(item, index) in objDate[hist]"
            :key="index"
          >
            <div class="header">
              <p class="source-name">
                Origem - {{ item.external_source || 'Desconhecida' }}
              </p>
              <p class="time">
                {{ dayjs(item.created_at).format('HH:mm:ss') }}
              </p>
            </div>
            <div class="d-flex align-end w-100 justify-between">
              <div class="d-flex flex-column" style="max-width: 55%">
                <p>{{ type[item.field] }}</p>
                <span :title="formatType(item)">{{ formatType(item) }}</span>
              </div>

              <div
                class="source"
                :title="`${getUnitName(item.unit_id)} - ${getSourceName(
                  item.origin
                )}`"
              >
                {{ getUnitName(item.unit_id) }} -
                {{ getSourceName(item.origin) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-card>

    <div v-if="objDate.length === 0 && !loading" class="empty">
      <i class="fal fa-list-timeline" />
      <p>Não há nenhum evento para ser exibido aqui.</p>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import { EnvironmentsAPI } from '@/api/Environments';
import BaseLoading from '@/components/Loading';
import BaseCard from '@/components/Card';

export default {
  data() {
    return {
      loading: true,
      availableSources: [],
      units: [],
      sources: {},
      objDate: [],
      history: {},
      dayjs,
      type: {
        name: 'Alteração de nome',
        birthdate: 'Alteração de data de nascimento',
        email: 'Alteração de email',
        addresses: 'Alteração de endereço',
        conversion: 'Fez uma conversão',
      },
    };
  },
  props: {
    clientValue: {
      type: Object,
      required: true,
    },
  },

  async mounted() {
    const logs = this.getLogs(this.clientValue.logs.customer);
    this.objDate = [];
    this.formatDate(logs);
    await this.getAvaliableSources();
    await this.getUnits();
    this.history = logs;
    // this.offload();
  },

  watch: {
    clientValue(newVal) {
      const logs = this.getLogs(newVal.logs.customer);
      this.objDate = [];
      this.formatDate(logs);
      this.history = logs;
      this.getAvaliableSources();
      this.getUnits();
      // this.offload();
    },
  },
  components: {
    BaseLoading,
    BaseCard,
  },
  methods: {
    offload() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },

    getLogs(customerLogs) {
      let filteredLogs = customerLogs.filter(
        (customerLog) =>
          ['[]', null, ''].indexOf(customerLog.value) === -1 &&
          customerLog.field !== 'new_profile'
      );

      filteredLogs = filteredLogs.sort((a, b) =>
        dayjs(a.created_at).isAfter(dayjs(b.created_at)) ? 1 : -1
      );
      this.offload();
      return filteredLogs.reverse();
    },

    formatType(item) {
      if (item.field === 'name') {
        return item.value || 'valor não definido';
      }
      if (item.field === 'birthdate') {
        return item.value
          ? item.value
              .substring(0, 10)
              .split('-')
              .reverse()
              .join()
              .replaceAll(',', '/')
          : 'valor não definido';
      }
      if (item.field === 'email') {
        return item.value || 'valor não definido';
      }
      if (item.field === 'addresses') {
        const address = JSON.parse(item.value);

        const formattedAddress = this.formatAddress(address);

        return formattedAddress || 'valor não definido';
      }

      if (item.field === 'conversion') {
        return (
          ((item.value && parseFloat(item.value)) || 0.0).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            }
          ) || 'valor não definido'
        );
      }
    },

    formatAddress(address) {
      if (address.geolocation) delete address.geolocation;

      let formattedAddress = address.street ? `${address.street}, ` : '';
      formattedAddress = formattedAddress.concat(
        address.number ? `${address.number} | ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.complement ? `${address.complement} | ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.neighborhood ? `${address.neighborhood} - ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.city ? `${address.city}/` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.state ? `${address.state} ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.zipcode
          ? `(${address.zipcode.replace(/^(\d{5})(\d)/, '$1-$2')})`
          : ''
      );

      return formattedAddress;
    },

    async getUnits() {
      const response = await EnvironmentsAPI.getUnits(false);
      if (response.getData([]).length > 0) {
        this.units = response.getData([]);
      }
    },
    async getAvaliableSources() {
      this.availableSources = (
        await EnvironmentsAPI.getAvailableSources(false)
      ).getData([]);
    },
    getUnitName(unitId) {
      const unit = (this.units || []).find((unit) => unit.id == unitId);
      return `${unit ? unit.name : 'desconhecida'}`;
    },
    getSourceName(origin) {
      for (const source of this.availableSources) {
        if (source.alias == origin) {
          return source.name;
        }
      }
      return 'Fonte Desconhecida';
    },

    formatDay(date) {
      if (!date) {
        return '-';
      }
      return date
        .substring(0, 10)
        .split('-')
        .reverse()
        .join()
        .replaceAll(',', '/');
    },
    formatDate(data) {
      data.map((item) => {
        if (!this.objDate.includes(item.created_at.substring(0, 10))) {
          this.objDate.push(item.created_at.substring(0, 10));
        }
      });
      this.searchEvents();
    },
    searchEvents() {
      const logs = this.getLogs(this.clientValue.logs.customer);

      logs.forEach((item) => {
        if (this.objDate[item.created_at.substring(0, 10)]) {
          this.objDate[item.created_at.substring(0, 10)].push(item);
        } else {
          this.objDate[item.created_at.substring(0, 10)] = [item];
        }
      });
    },
  },
};
</script>

<style scoped>
.records {
  margin-bottom: 20px;
}
.records .record {
  background: #2e3438;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
}

.record .header {
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  padding-bottom: 15px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.record p.source-name {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #aaaaab;
}
.record p.time {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #aaaaab;
}
.records .record p:not(.source-name, .time):not(:first-child) {
  margin-top: 10px;
  padding-top: 10px;
}
.records .date {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #aaaaab;
  margin-bottom: 20px;
}
.records .timeline {
  position: relative;
}
.records .timeline .dot {
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: var(--default);
  border-radius: 50%;
}
.records .timeline:before {
  content: '';
  position: absolute;
  height: 100%;
  border-left: 2px dashed #6f767e80;
  width: 2px;
  left: 3px;
}
.records .record p:not(.source-name, .time) {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 10px;
  opacity: 0.6;
}
.records .record span {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.9;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
}
.records .record .source {
  background: #353d42;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 15px;
  cursor: default;
}
.loading {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty i {
  font-size: 40px;
  margin-bottom: 25px;
  color: var(--icon-inactive);
}

.empty p {
  font-size: 16px;
  line-height: 19px;
  color: rgba(204, 204, 204, 0.45);
}
</style>
