<template>
  <div class="event">
    <div class="time desktop">
      <span>{{ formatHour(event.created_at) }}</span>
    </div>
    <div class="details">
      <div class="type">
        <div class="bar" />
        <div class="time mobile">
          <span>{{ formatHour(event.created_at) }}</span>
        </div>

        <div :class="{ flex: isMobile }" class="d-flex flex-column">
          <p>{{ getEventType(event.type) }}</p>
          <span class="unity">{{ event.unit }} - {{ event.source }}</span>
        </div>

        <div :title="`${event.unit} - ${event.source}`" class="store">
          <i class="fal fa-store" />
        </div>

        <base-button
          design="three mini icon"
          v-if="event.type !== TYPES.CONVERSION && event.customer && isMobile"
          @click="$emit('showProfile', event.customer && event.customer.id)"
        >
          <i class="far fa-user" />
        </base-button>
      </div>

      <div class="infos">
        <p class="not-found" v-if="!event.customer">
          {{
            event.type === TYPES.NEW_ACCOUNT
              ? 'Nome não registrado'
              : 'Cliente não identificado'
          }}*
        </p>
        <p
          class="name"
          v-if="
            event.customer &&
            event.customer.name &&
            TYPES.CHANGE_NAME !== event.type &&
            TYPES.NEW_ACCOUNT !== event.type
          "
        >
          {{ event.customer.name }}
        </p>
        <p class="phone" v-if="event.customer">
          <i class="fal fa-mobile" />
          {{ event.customer ? formatNumber(event.customer.phone) : '' }}
        </p>
        <p
          :class="{ [event.type]: true }"
          v-if="event.type != TYPES.NEW_ACCOUNT"
        >
          <i v-if="hasIcon(event.type)" :class="getIcon(event.type)" />
          {{ formatValue(event.type, event.value) }}
        </p>
        <p
          :class="{ 'not-found': !formatValue(event.type, event.value) }"
          v-if="event.type == TYPES.NEW_ACCOUNT"
        >
          {{ formatValue(event.type, event.value) || 'Nome não registrado*' }}
        </p>
      </div>

      <div class="buttons">
        <base-button
          design="three mini ml_15"
          v-if="event.customer && !isMobile"
          @click="$emit('showProfile', event.customer && event.customer.id)"
        >
          Ver perfil
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import BaseButton from '@/components/Button';

const components = {
  BaseButton,
};

const TYPES = {
  NEW_ACCOUNT: 'new_profile',
  CONVERSION: 'conversion',
  CHANGE_EMAIL: 'email',
  CHANGE_NAME: 'name',
  CHANGE_BIRTHDAY: 'birthdate',
  ADD_ADDRESS: 'addresses',
};

export default {
  components,
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data: () => ({ TYPES }),
  methods: {
    formatNumber(number) {
      if (!number) return number;
      const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
      const str = number.replace(/[^0-9]/g, '').slice(2, 13);
      return str.replace(regex, '($1) $2-$3');
    },
    getEventType(type) {
      switch (type) {
        case TYPES.NEW_ACCOUNT:
          return 'Novo perfil';
        case TYPES.CONVERSION:
          return 'Conversão';
        case TYPES.CHANGE_EMAIL:
          return 'Alteração e-mail';
        case TYPES.CHANGE_NAME:
          return 'Alteração nome';
        case TYPES.CHANGE_BIRTHDAY:
          return 'Alteração aniversário';
        case TYPES.ADD_ADDRESS:
          return 'Adição endereço';
      }
    },

    hasIcon(type) {
      return (
        [TYPES.CHANGE_EMAIL, TYPES.CHANGE_BIRTHDAY, TYPES.ADD_ADDRESS].includes(
          type
        ) === true
      );
    },

    getIcon(type) {
      const icons = {
        [TYPES.CHANGE_EMAIL]: 'fal fa-envelope',
        [TYPES.CHANGE_BIRTHDAY]: 'fal fa-cake-candles',
        [TYPES.ADD_ADDRESS]: 'fal fa-location-dot',
      };

      return icons[type];
    },

    formatValue(eventType, value) {
      switch (eventType) {
        case TYPES.CONVERSION:
          return this.formatCurrency(+value);
        case TYPES.CHANGE_EMAIL:
          return value;
        case TYPES.CHANGE_NAME:
          return value;
        case TYPES.CHANGE_BIRTHDAY:
          return value
            ? dayjs(value.split(' ')[0]).format('DD/MM/YYYY')
            : ' - ';
        case TYPES.ADD_ADDRESS:
          return this.formatAddress(value);
        case TYPES.NEW_ACCOUNT:
          return this.formatProfile(value);
      }
    },

    formatProfile(profileString) {
      const profile = JSON.parse(profileString);
      return profile.name;
    },

    formatHour(time) {
      return dayjs(time).format('HH:mm');
    },
    formatCurrency(value) {
      return value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    formatAddress(addressString) {
      const address = JSON.parse(addressString);

      if (addressString == '[]') {
        return '-';
      }

      if (address.geolocation) delete address.geolocation;

      let formattedAddress = address.street ? `${address.street}, ` : '';
      formattedAddress = formattedAddress.concat(
        address.number ? `${address.number} | ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.complement ? `${address.complement} | ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.neighborhood ? `${address.neighborhood} - ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.city ? `${address.city}/` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.state ? `${address.state} ` : ''
      );

      formattedAddress = formattedAddress.concat(
        address.zipcode
          ? `(${address.zipcode.replace(/^(\d{5})(\d)/, '$1-$2')})`
          : ''
      );

      return formattedAddress;
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>
<style scoped>
.date {
  color: rgba(235, 235, 235, 0.55);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
  font-weight: 900;
}

.event {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}

.event:last-child {
  margin-bottom: 30px;
}

.event .time {
  background: #1e2124;
  border-radius: 15px;
  color: rgba(111, 118, 126, 1);
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-right: 32.5px;
}

.event .details {
  background: var(--box);
  border-radius: 10px;
  padding: 15px 15px 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex: 1;
  min-height: 65px;
}

.event .details:before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  position: absolute;
  left: -22px;
  margin: auto;
  background: var(--default);
}

.event .details .type {
  background: #23282b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 30px;
  position: relative;
  height: 100%;
  position: absolute;
  border-radius: 5px 0 0 5px;
  left: 0;
  width: 245px;
}

.event .details .type .bar {
  transform: rotate(180deg);
  background: #32393d;
  width: 2px;
  border-radius: 10px;
  position: absolute;
  left: 10px;
  height: calc(100% - 30px);
}

.event .details .type .store {
  border-radius: 50%;
  border: 1px solid rgba(111, 118, 126, 0.11);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(111, 118, 126, 0.85);
  font-size: 10px;
}

.event .details .type p {
  border: 1px dashed rgba(111, 118, 126, 0.2);
  padding: 5px 15px;
  border-radius: 5px;
  color: rgba(235, 235, 235, 0.8);
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
}

.event .details .infos {
  display: flex;
  align-items: center;
  margin-left: 245px;
  padding: 0 20px;
  flex: 1;
}

.event .details .infos p {
  color: rgba(170, 170, 171, 0.9);
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
}

.event .details .infos p.name {
  font-weight: 500;
}

.event .details .infos p.phone {
  white-space: nowrap;
}

@media screen and (min-width: 992px) {
  .event .details .infos p.addresses {
    max-width: 50%;
    text-overflow: ellipsis;
  }
}

.event .details .infos p.address {
  font-weight: bold;
  color: #b1b1b2;
}

.event .details .infos p.not-found {
  font-style: italic;
  color: rgba(170, 170, 171, 0.55);
}

.event .details .infos i {
  color: rgba(170, 170, 171, 0.9);
  font-size: 15px;
  margin-right: 10px;
}

.event .details .infos p:not(:last-child) {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(111, 118, 126, 0.55);
}

.event .details .buttons {
  display: flex;
}

.event .time.mobile,
.event .details .type span.unity {
  display: none;
}

@media screen and (max-width: 991px) {
  .date {
    margin-bottom: 35px;
  }

  .event .details {
    border-radius: 10px;
    flex-direction: column;
    background: rgba(37, 42, 46, 0.55);
    padding: 0;
  }

  .event .details:before {
    left: -25px;
    width: 6px;
    height: 6px;
  }

  .event .details .type {
    position: relative;
    width: 100%;
    background: rgba(35, 40, 43, 0.3);
    border-radius: 10px 10px 0px 0px;
    padding: 15px 25px;
    margin-bottom: 15px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .event .details .type p {
    border: none;
    padding: 0;
  }
  .event .details .type span.unity {
    display: flex;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: rgba(170, 170, 171, 0.55);
    margin-top: 5px;
  }
  .event .details .type .store {
    display: none;
  }
  .event .details .infos {
    margin-left: 0;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .event .details .infos p:not(:last-child) {
    margin-right: 0;
    margin-bottom: 15px;
    padding-right: 0;
    padding-bottom: 15px;
    border-right: none;
    border-bottom: 1px solid rgba(111, 118, 126, 0.15);
    width: 100%;
  }
  .event .details .type .bar {
    height: 15px;
    top: 15px;
  }

  .event .details .buttons:not(:empty) {
    width: 100%;
    padding: 0 20px 15px;
    justify-content: center;
    flex-direction: column;
  }
  .event .details .buttons {
    margin-top: 20px;
  }
  .event .details .buttons button {
    width: 100%;
  }
  .event .details .buttons button:not(:last-child) {
    margin-bottom: 10px;
  }
  .event .time.mobile {
    display: flex;
    font-size: 12px;
    line-height: 15px;
    color: #6f767e;
    margin-right: 15px;
    padding: 0;
  }
  .event .time.desktop {
    display: none;
  }
}
</style>
