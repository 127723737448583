<template>
  <base-box>
    <div class="label-sb">
      <base-label :text="title" />
      <p v-if="drawer" class="see-all" @click="drawerAll = !drawerAll">
        Ver todos
      </p>
      <p v-if="showChangeMonth" class="change-m" @click="changePeriod">
        {{ buttonLabel ? buttonLabel : "Trocar mês" }}
      </p>
      <div class="growth-index" v-if="growthLabel">
        <p v-if="growthMonth && growthYear">
          Em relação a {{ Utils.getMonthNames(+growthMonth) }} de
          {{ growthYear }}
        </p>
        <p v-else-if="growthYear">Em relação a {{ growthYear }}</p>
        <p v-else>Em relação ao mês anterior</p>
        <div class="values">
          <p :class="growth.value < 0 ? 'negative' : 'positive'">
            {{ growth.value > 0 ? "+" : ""
            }}{{
              maskMoney ? Utils.convertMoney(growth.value || 0) : growth.value
            }}
          </p>
          <p>({{ growth.percentage }}%)</p>
        </div>
      </div>
    </div>

    <base-chart
      :reload="reloadChart"
      :chartType="type"
      :chartData="chartData"
      :chartOptions="chartOptions"
      :width="width"
      :height="height"
      class="chart-labeled my_5"
    />

    <!-- Drawer -->
    <base-drawer title="Ver todos" :open="drawerAll">
      <base-card>
        <base-table
          :title="title"
          :headers="headers"
          :items="allItems"
          :drawer-mode="true"
        />
      </base-card>
    </base-drawer>
  </base-box>
</template>

<script>
import BaseChart from "@/components/Chart.js";
import BaseBox from "@/components/Box";
import BaseLabel from "@/components/Label";
import BaseDrawer from "@/components/Drawer";
import BaseCard from "@/components/Card";
import BaseTable from "@/containers/Table";

import Utils from "@/utils";

export default {
  props: {
    growthMonth: null,
    growthYear: null,
    reloadChart: {},
    showChangeMonth: {
      default: false,
    },
    from: {
      default: "",
    },
    buttonLabel: {
      default: "",
    },
    maskMoney: {
      default: false,
    },
    growthLabel: {
      default: false,
    },
    drawer: {
      default: false,
    },
    title: {},
    type: {
      default: "doughnut",
    },
    headers: {},
    allItems: {},
    chartData: {},
    chartOptions: {},
    width: { type: Number },
    height: { type: Number },
  },
  data() {
    return {
      drawerAll: false,
      growth: {
        value: 0,
        percentage: "0",
      },
      Utils,
    };
  },
  components: {
    BaseChart,
    BaseBox,
    BaseLabel,
    BaseDrawer,
    BaseCard,
    BaseTable,
  },
  watch: {
    reloadChart() {
      this.calculeGrowth();
    },
  },
  methods: {
    calculeGrowth() {
      if (this.chartData.datasets.length == 2) {
        const values = [];
        this.chartData.datasets.forEach((item) => {
          values.push(item.data.reduce((a, b) => a + b, 0));
        });

        this.growth.value = values[0] - values[1];
        this.growth.percentage = (
          ((values[0] - values[1] || 1) / (values[1] || 1)) *
          100
        ).toFixed(0);
        this.$forceUpdate();
      }
    },
    changePeriod() {
      this.$emit("changePeriod", this.from);
    },
  },
  created() {
    this.calculeGrowth();
  },
};
</script>

<style scoped>
.box.no-shadow {
  box-shadow: none;
}
.chart-labeled {
  max-height: calc(100% - 45px);
}
.label-sb {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.see-all {
  margin-bottom: 10px;
  font-size: 1.4em;
  color: #c9c9c973;
  cursor: pointer;
  transition: color linear 0.2s;
}
.see-all:hover {
  color: var(--default);
}
.growth-index {
  text-align: center;
  font-size: 1.2em;
  color: #999;
}
.values {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.values p:first-child {
  background: var(--background);
  padding: 5px 10px;
  border-radius: 5px 0 0 5px;
  color: #999;
}
.values p.positive:first-child {
  color: #0ba584;
}
.values p.negative:first-child {
  color: var(--error);
}
.values p:last-child {
  font-size: 12px;
  background: var(--background-up);
  padding: 5px 10px;
  border-radius: 0 5px 5px 0;
}
.change-m {
  position: absolute;
  top: 0;
  left: calc(50% - 35px);
  font-size: 1.4em;
  color: #c9c9c996;
  margin-bottom: 5px;
  cursor: pointer;
  transition: color linear 0.2s;
}
.change-m:hover {
  color: var(--default);
}
</style>