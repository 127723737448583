<template>
  <div>
    <slot></slot>
    <BaseMessage v-show="messages.length > 0" :design="'red'" :messages="messages" class="mb_15 mt_10"></BaseMessage>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import BaseMessage from "@/components/MultiplesMessages.vue";
import InputsMessages from "@/plugins/inputValidationMessages";

export default {
  mounted() {
    // Mapeando os inputs dentro do form
    this.$children.forEach((child) => {
      const inputId = child.inputId || child.id || null;
      if (inputId) {
        this.errors[child.inputId] = "";
      }
    });
  },
  data() {
    return {
      errors: {},
      messages: [],
    };
  },
  methods: {
    validateInputs() {
      this.messages = [];

      this.$children.forEach((child) => {
        child.validate && child.validate();
      });

      return this.messages.length === 0;
    },
    setErrorValidation(inputId, messageCode, label = '') {
      const customLabel = label ? `(${label})` : '';
      this.errors[inputId] = `${InputsMessages.getMessage(messageCode)} ${customLabel}`;
      this.messages = Object.values(this.errors).filter((message) => message !== "");
    },
    removeErrorValidation(inputId) {
      this.errors[inputId] = "";
      this.messages = Object.values(this.errors).filter((message) => message !== "");
    },
  },
  components: {
    BaseMessage,
  },
};
</script>