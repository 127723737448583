<template>
  <base-drawer :open="drawer.open" :close="drawer.close" title="Filtragem">
    <base-card>
      <base-label text="Fontes" class="mb_20" />
      <base-select
        title="Selecione a fonte de dados..."
        class="w-100"
        v-model="payload.sourceId"
        @change="(value) => mountFilter('unitSource', value)"
      >
        <option :value="null">Todas as fontes</option>
        <option
          v-for="(unitSource, unitSourceIndex) in unitsSources"
          :value="unitSource.sourceId"
          :key="unitSourceIndex"
        >
          {{ getSourceName(unitSource.source) }} / Unidade {{
            getUnitName(unitSource.unit)
          }}
        </option>
      </base-select>
    </base-card>

    <base-card>
      <base-label text="Tipo de evento" class="mb_20" />
      <base-select
        title="Selecione o tempo..."
        class="w-100"
        v-model="payload.eventType"
        @change="(value) => mountFilter('eventType', value)"
      >
        <option :value="null">Todos os tipos</option>
        <option
          v-for="(alias, aliasIndex) in Object.values(TYPES)"
          :value="alias"
          :key="aliasIndex"
        >
          {{ getEventType(alias) }}
        </option>
      </base-select>
    </base-card>

    <template slot="footer">
      <base-button class="w-100 big" @click.native="searchDrawer">
        Filtrar
      </base-button>
    </template>
  </base-drawer>
</template>

<script>
import BaseTitle from '@/components/Title';
import BaseDrawer from '@/components/Drawer';
import BaseCard from '@/components/Card';
import BaseButton from '@/components/Button';
import BaseInput from '@/components/Input';
import BaseLabel from '@/components/Label';
import BaseCheckbox from '@/components/Checkbox';
import BaseSelect from '@/components/Select';
import { EnvironmentsAPI } from '@/api/Environments';

const components = {
  BaseTitle,
  BaseDrawer,
  BaseCard,
  BaseButton,
  BaseInput,
  BaseLabel,
  BaseCheckbox,
  BaseSelect,
};

const TYPES = {
  NEW_ACCOUNT: 'new_profile',
  CONVERSION: 'conversion',
  CHANGE_EMAIL: 'email',
  CHANGE_NAME: 'name',
  CHANGE_BIRTHDAY: 'birthdate',
  ADD_ADDRESS: 'addresses',
};

export default {
  components,
  props: {
    drawer: {
      type: Object,
      default: () => ({ open: false, close: false }),
    },
    sourceId: { type: Number },
    eventType: { type: String },
  },
  data: () => ({
    TYPES,
    units: [],
    unitsSources: [],
    availableSources: [],
    payload: {
      sourceId: null,
      eventType: null,
    },
  }),
  mounted() {
    this.getUnits();
    this.getPriorities();
    this.getAvaliableSources();
  },
  methods: {
    async getUnits() {
      const response = await EnvironmentsAPI.getUnits();
      if (response.getData([]).length > 0) {
        this.units = response.getData([]);
      }
    },
    async getAvaliableSources() {
      this.availableSources = (
        await EnvironmentsAPI.getAvailableSources()
      ).getData([]);
    },
    async getPriorities() {
      const response = await EnvironmentsAPI.getConfigurations();
      if (response.getData({})) {
        const priorities = response.getData({ priority: [{ sourcesPriority: [] }] }).priority || [{ sourcesPriority: [] }];
        const firstSources = priorities[0];
        this.unitsSources = firstSources.sourcesPriority;
      }
    },
    getUnitName(unitId) {
      const unit = this.units.find((unit) => unit.id == unitId);
      return `${unit ? unit.name : 'desconhecida'}`;
    },
    getSourceName(origin) {
      for (const source of this.availableSources) {
        if (source.alias == origin) {
          return source.name;
        }
      }
      return 'Fonte Desconhecida';
    },
    mountFilter(field, value) {
      if (value && value.target) {
        return false;
      }
      this.payload[field] = value;
    },
    searchDrawer() {
      const unitsSource = this.unitsSources.find(unitSource => this.payload.sourceId == unitSource.sourceId);
      this.$emit('onFilter', {
        eventType: this.payload.eventType,
        sourceId: unitsSource ? unitsSource.source: null,
        unitId: unitsSource ? +unitsSource.unit: null,
      });
    },
    getEventType(type) {
      switch (type) {
        case TYPES.NEW_ACCOUNT:
          return 'Novo perfil';
        case TYPES.CONVERSION:
          return 'Conversão';
        case TYPES.CHANGE_EMAIL:
          return 'Alteração e-mail';
        case TYPES.CHANGE_NAME:
          return 'Alteração nome';
        case TYPES.CHANGE_BIRTHDAY:
          return 'Alteração aniversário';
        case TYPES.ADD_ADDRESS:
          return 'Adição endereço';
      }
    },
  },
};
</script>

<style>
</style>