<template>
  <base-drawer
    :open="drawer.open"
    :close="drawer.close"
    :title="formatNumber(customer.phone)"
  >
    <base-tabs
      :tabs="modeTabs"
      :tabActived="tabActive"
      @change="changeTab"
      class="mx_25"
    />

    <Indexes
      v-if="tabActive === 0"
      :key="Math.random()"
      :clientValue="JSON.parse(JSON.stringify(customer))"
    />
    <Data
      v-if="tabActive === 1"
      :clientValue="JSON.parse(JSON.stringify(customer))"
    />
    <!-- <Conversions v-if="tabActive === 2" /> -->

    <Historic
      v-if="tabActive === 2"
      :clientValue="JSON.parse(JSON.stringify(customer))"
    />
  </base-drawer>
</template>

<script>
import BaseDrawer from '@/components/Drawer';
import BaseCard from '@/components/Card';
import BaseTabs from '@/components/Tabs';
import Indexes from '@/containers/Database/Indexes';
import Data from '@/containers/Database/Data';
import Conversions from '@/containers/Database/Conversions';
import Historic from '@/containers/Database/Historic';

const components = {
  BaseDrawer,
  BaseCard,
  BaseTabs,
  Indexes,
  Data,
  Conversions,
  Historic,
};

export default {
  components,
  props: {
    customer: { type: Object, default: () => {} },
    drawer: {
      type: Object,
      default: () => ({
        open: false,
        close: false,
      }),
    },
  },
  data: () => ({
    tabActive: 0,
    modeTabs: [
      { name: 'Índices', value: 0 },
      { name: 'Dados', value: 1 },
      /* { name: 'Conversões', value: 2 }, */
      { name: 'Histórico', value: 2 },
    ],
  }),
  methods: {
    changeTab(tab) {
      this.tabActive = tab;
    },
    formatNumber(number) {
      if (!number) return number;
      const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
      const str = number.replace(/[^0-9]/g, '').slice(2, 13);
      return str.replace(regex, '($1) $2-$3');
    },
  },
};
</script>

<style scoped>
.customers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.customers .customer .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #292f33;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
}

.customers .customer .header .phone {
  background: #31383d;
  padding: 5px 15px;
  border-radius: 10px;
  color: #aaaaab;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.customers .customer .header i {
  font-size: 10px;
  color: #aaaaab;
}

.customers .customer .header:hover {
  background: #2d3338;
  cursor: pointer;
}

.customers .customer .header:hover i {
  color: var(--default);
}

.customers .customer .content {
  background: var(--box);
  border-radius: 0 0 10px 10px;
  padding: 20px;
}

.customers .customer .content .item {
  display: flex;
  justify-content: space-between;
}

.customers .customer .content .item:not(:first-child) {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #6f767e33;
}

.customers .customer .content .item div:last-child {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin: auto 0;
}

.customers .customer .content .item p {
  color: #aaaaab;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.6;
  margin-bottom: 10px;
}

.customers .customer .content .item .progress-completeness {
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.customers .customer .content .item .progress-completeness.red {
  background: rgba(255, 80, 45, 0.15);
  color: #ff502d;
}

.customers .customer .content .item .progress-completeness.yellow {
  background: rgba(255, 241, 173, 0.15);
  color: #fff1ad;
}

.customers .customer .content .item .progress-completeness.green {
  background: rgba(130, 217, 151, 0.15);
  color: #82d997;
}

.customers .customer .content .item span {
  color: #aaaaab;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.9;
}

@media screen and (max-width: 991px) {
  .customers {
    grid-template-columns: repeat(1, 1fr);
  }
}

.mx_25 {
  margin-left: 25px;
  margin-right: 25px;
}
</style>
