<template>
  <div class="conversion-details">
    <div class="header">
      <div class="order">
        <p class="number">Pedido #23</p>
        <span class="value">R$ 20,55</span>
      </div>
      <span class="date">{{ '10/04/2022' }}</span>
    </div>

    <div class="items">
      <p class="item">Copacabana - Goomer</p>
      <p class="item"><i class="fal fa-gift" />PIZZA15</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    conversions: [],
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.conversion-details {
  padding: 20px;
  background: #2e3438;
  border-radius: 10px;
}

.conversion-details .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.conversion-details .header .order .number {
  color: #aaaaab;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 15px;
}

.conversion-details .header .order .value {
  color: #aaaaab;
  opacity: 0.7;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.conversion-details .header .date {
  color: #aaaaab;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.conversion-details .items {
  display: flex;
}

.conversion-details .item {
  background: #353d42;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 14px;
  color: #aaaaab;
  opacity: 0.85;
  margin-right: 15px;
}

.conversion-details .item i {
  margin-right: 10px;
}
</style>
